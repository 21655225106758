<template>
    <vue-pure-lightbox class="lightbox">
    <section class="verify has-text-centered">
        <div class="container">
            <table class="reg-form has-text-centered">
            <tr>
                <td class="form-header">
                    <p class="org-description is-size-5"></p>
                </td>
            </tr>
            <tr>
                <td class="form-content">
                    
                    <p class="org-description is-size-5">{{ this.msg }}</p>
                    <div><img class="center-image" v-bind:src="require('../assets/' + img)" width="100px"></div>
                    <button class="button submit is-primary is-outlined" @click="submit">
                        <span>{{ this.btn }}</span>
                    </button>

                </td>
            </tr>
            </table>
        </div>
    </section>
    </vue-pure-lightbox>
</template>

<script>
    export default {
        name: "verify",
        props: {
            tk: {
                type: String,
                default: ""
            }
        },
        data() {
            return {
                results: false,
                msg: "Something went wrong... Please contact locker@3df.io.",
                img: "sad-lock.svg",
                btn: "Retry"
            }
        },
        created() {
            this.verify()
        },
        methods: {
            verify() {
                requestAnimationFrame(() => {
                    this.loading = true;
                    if (this.tk != "") {
                        this.$store.dispatch('auth/verify', this.tk).then(
                        (response) => {
                                console.log(response.data);
                                if (response.status == true) {
                                    this.results = true;
                                    this.btn = "Login Now";
                                    this.img = "smiley-lock.svg"
                                    this.msg = "Your e-mail has been successfully verfied!";
                                } else {
                                    this.results = false;
                                    this.btn = "OK";
                                    this.img = "sad-lock.svg";
                                    this.msg = response.message;
                                }
                                
                            },
                            error => {
                                this.loading = false;
                                this.results = false;
                                console.log(error);
                                this.btn = "OK";
                                this.msg = "sad-lock.svg";
                                this.msg = "Unable to verify. Please contact locker@3df.io";
                            }
                        );
                    }
                });
            },
            submit() {
                requestAnimationFrame(() => {
                    if (this.results == false) {

                        this.$router.push({ path: "/" });
                    }

                    if (this.results == true) {
                        this.$router.push({ path: "/dashboard" });
                    }
                });
            }
        }
    }
</script>

<style lang="scss">
    $main-color: #aac734;
    .lightbox {
        width: 100%; height: 100%;
        background: rgba(0, 0, 0, .4);
    }
    .verify {
        padding-top: 30px;
        padding-bottom: 100px;
        width: 20%;
    }
    .container {
        width: 100%;
        justify-content: center;
    }
    .submit {
        margin-top: 30px;
        text-align: center;
    }
    .reg-form {
        width: 100%;
        max-width: 600px;
        border-radius: 10px;
        box-shadow: 0px 2px 30px rgba(0,0,0,0.3);
        margin-left: auto;
        margin-right: auto;
        margin-top: 0px;
    }
    .form-header {
        font-weight: bold;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 20px;
        background: $main-color;
    }
    .form-content {
        background: #F5F5F5;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 30px;
        padding-left: 10%;
        padding-right: 10%;
    }
    .form-content .label {
        font-size: 0.78rem;
        text-align: left;
        padding-left: 5px;
        font-weight: 400;
    }
    .center-image {
        padding-top: 20px;
        align-content: center;
    }
</style>